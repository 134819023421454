<template>
    <nav id="appNavbar" class="navbar navbar-expand-lg bg-dark navbar-dark fixed-top ">
        <div class="container align-items-center">
            <a class="navbar-brand font-brand fs-3 d-none d-lg-inline-block"  :href="brand.parent_url">
                <img :src="brand.logo" :alt="brand.name" style="height: 60px">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand font-brand fs-3 d-inline-block d-lg-none" :href="brand.self_url">
              <img :src="brand.logo" :alt="brand.name" style="height: 60px">
            </a>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav mx-auto">
                    <li class="nav-item">
                        <a class="nav-link" :href="brand.parent_url">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :href="brand.self_url">Shop</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :href="brand.parent_url + 'events'">Events</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :href="brand.parent_url + 'about'">Gurusshri</a>
                    </li>
                </ul>
            </div>
            <UserAuthModal />
            <WishlistViewModal show="btn" />
            <CartViewModal show="btn" />
        </div>
    </nav>
    <UserAuthModal />
    <WishlistViewModal show="view" />
    <CartViewModal show="view" />
</template>

<script>
import UserAuthModal from '../components/modals/UserAuth.vue'
import CartViewModal from '../components/modals/CartView.vue'
import WishlistViewModal from '../components/modals/WishlistView.vue'

export default {
  name: 'AppNavbar',
  components: {
    UserAuthModal,
    CartViewModal,
    WishlistViewModal
  },
  computed: {
    brand () {
      return this.$store.getters['repo/getBrand']
    }
  }
}
</script>
