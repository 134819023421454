<template>
  <div class="container py-5">

    <div v-if="payment === 'success'" class="d-flex fs-4 text-success">
      <i class="bi bi-check-circle-fill me-3"></i>
      <p class="fw-bold font-title">Order Confirmed</p>
    </div>

    <div v-if="payment === 'canceled'" class="d-flex fs-4 text-success">
      <i class="bi bi-exclamation-circle-fill me-3"></i>
      <p class="fw-bold font-title">Payment Canceled</p>
    </div>

    <div v-if="payment === 'failed'" class="d-flex fs-4 text-success">
      <i class="bi bi-exclamation-circle-fill me-3"></i>
      <p class="fw-bold font-title">Payment Failed</p>
    </div>

    <router-link class="btn btn-lg btn-outline-primary" v-if="payment === 'failed'" 
      :to="{ name: 'Checkout' }">Retry Payment</router-link>

    <router-link class="btn btn-lg btn-outline-success" v-if="payment === 'success'" 
      :to="{ name: 'Orders' }">Track Order</router-link>

    <router-link class="btn btn-lg btn-outline-dark ms-3" 
      :to="{ name: 'Home' }">Back to Shop</router-link>

  </div>
</template>

<script>
export default {
  name: 'PayPage',
  data () {
    return {
      payment: ''
    }
  },
  mounted () {
    const path = this.$router.currentRoute
    const status = path.value.query.payment
    if (['success', 'canceled', 'failed'].includes(status) && localStorage.getItem('signature')) {
      this.payment = status
      this.$store.dispatch('startLoading').then(() => {
        this.$store.dispatch('shop/sendRequestToCreatePayment', {
          razorpay_order_id: localStorage.getItem('order_id'),
          razorpay_payment_id: localStorage.getItem('payment_id'),
          razorpay_signature: localStorage.getItem('signature')
        }).then(() => {
          this.$store.dispatch('shop/syncUserCartItems').then(() => {
            this.$store.dispatch('endLoading')
          })
        })
      })
    } else {
      this.$router.push({ name: 'NotFound' })
    }
  }
}
</script>
