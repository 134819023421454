export default {
  path: '/user',
  name: 'User',
  beforeEnter: (to, from) => {
    if (!localStorage.getItem('login')) {
      return { name: 'Login', query: { redirect: to.name } }
    }
    // if (localStorage.getItem('verified') === 'false') {
    //   return { name: 'Verify' }
    // }
    return true
  },
  children: [
    {
      path: 'checkout',
      name: 'Checkout',
      component: () => import('@/pages/user/CheckoutPage.vue')
    },
    {
      path: 'payment',
      name: 'Payment',
      component: () => import('@/pages/user/PaymentPage.vue')
    },
    {
      path: 'order',
      name: 'Order',
      component: () => import('@/pages/user/OrderPage.vue')
    },
    {
      path: 'profile',
      name: 'Profile',
      component: () => import('@/pages/user/ProfilePage.vue')
    },
    {
      path: 'orders',
      name: 'Orders',
      component: () => import('@/pages/user/OrdersPage.vue')
    },
    {
      path: 'change-password',
      name: 'ChangePassword',
      component: () => import('@/pages/user/ChangePasswordPage.vue')
    }
  ]
}
