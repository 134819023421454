<template>
<div v-show="loading" style="background-color: #ffffff" :style="loading ? 'cursor: not-allowed' : ''">
    <div class="position-fixed top-0 bottom-0 start-0 end-0 ol-4" style="z-index: 10000">
        <div class="d-flex justify-content-center align-items-center h-100">
            <img :src="require('../../assets/loading.gif')" alt="page loading indicator" class="rounded-circle" style="width: 100px; height: 100px;">
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'PageLoading',
  computed: {
    loading () {
      return this.$store.getters.loading
    }
  }
}
</script>
