export default {
  path: '/auth',
  name: 'Auth',
  children: [
    {
      path: 'login',
      name: 'Login',
      component: () => import('@/pages/auth/LoginPage.vue')
    },
    {
      path: 'register',
      name: 'Register',
      component: () => import('@/pages/auth/RegisterPage.vue')
    },
    {
      path: 'forgot-password',
      name: 'ForgotPassword',
      component: () => import('@/pages/auth/ForgotPassword.vue')
    }
  ],
  beforeEnter: (to, from) => {
    if (localStorage.getItem('login')) {
      return { name: 'Home' }
    }
    return true
  }
}
