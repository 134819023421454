<template>
  <footer id="appCopyright" class="bg-dark op-9">
    <div class="container-xl">
        <div class="d-block d-lg-flex justify-content-center justify-content-lg-between p-4 text-white">
            <div class="text-center">
                {{ copyright.year }}
                <a class="text-reset td-none font-title" :href="copyright.website" v-text="copyright.brand"></a>
                . All rights reserved.
            </div>
            <div class="mt-2 mt-md-0 text-center">
                <span class="small">Developed By: </span>
                <a class="text-reset td-none font-title" href="https://covisor.in">Covisor Infotech Pvt. Ltd.</a>
            </div>
        </div>
    </div>
</footer>
</template>

<script>
export default {
  name: 'AppCopyright',
  computed: {
    copyright () {
      return this.$store.getters['repo/getCopyright']
    }
  }
}
</script>
