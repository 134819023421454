export default {
  path: '/',
  name: 'Shop',
  children: [
    {
      path: '',
      name: 'Home',
      component: () => import('@/pages/shop/HomePage.vue')
    },
    {
      path: 'categories/:category',
      name: 'Category',
      component: () => import('@/pages/shop/CategoryPage.vue')
    },
    {
      path: 'products/:name',
      name: 'Product',
      component: () => import('@/pages/shop/ProductPage.vue')
    }
  ]
}
