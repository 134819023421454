import { createStore } from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import repo from './modules/repo'
import data from './modules/data'
import shop from './modules/shop'
import forms from './modules/forms'

export default createStore({
  state: {
    ebag: {},
    loading: false
  },
  mutations: {
    setErrors (state, ebag) {
      state.ebag = ebag
    },
    setLoading (state, loading) {
      state.loading = loading
    }
  },
  actions: {
    setErrorsBag ({ commit }, ebag) {
      commit('setErrors', ebag)
    },
    clearErrorsBag ({ commit }) {
      commit('setErrors', {})
    },
    startLoading ({ commit }) {
      commit('setLoading', true)
    },
    endLoading ({ commit }) {
      commit('setLoading', false)
    }
  },
  getters: {
    errorsBag: state => state.ebag,
    loading: state => state.loading
  },
  modules: {
    auth: auth,
    user: user,
    repo: repo,
    data: data,
    shop: shop,
    forms: forms
  }
})
