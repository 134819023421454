<template>
  <div v-if="authenticated" class="dropdown">
    <button class="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="bi bi-person-circle fs-4" :class="authenticated ? 'text-warning' : 'text-muted'"></i>
    </button>
    <ul class="dropdown-menu">
      <li><h6 class="dropdown-header" v-text="username"></h6></li>
      <li><router-link class="dropdown-item" to="/user/orders">Orders</router-link></li>
      <li><router-link class="dropdown-item" to="/user/profile">Profile</router-link></li>
      <li><router-link class="dropdown-item" to="/user/change-password">Change Password</router-link></li>
      <li><router-link class="dropdown-item" to="/customer-support">Contact Support</router-link></li>
      <li><hr class="dropdown-divider"></li>
      <li><a class="dropdown-item" href="#" @click.once="logout">Logout</a></li>
    </ul>
  </div>
  <router-link v-else class="btn" :to="{ name: 'Login' }">
    <i class="bi bi-person-circle text-white fs-4"></i>
  </router-link>
</template>

<script>
export default {
  name: 'UserAuth',
  computed: {
    authenticated () {
      return this.$store.getters['auth/isLoggedIn']
    },
    username () {
      return this.$store.getters['auth/username']
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/sendLogoutRequest').then(() => {
          this.$router.replace({ name: 'Home' })
      })
    }
  }
}
</script>
