const logs = {

  enabled: false,
  step (message) {
    console.log('step - ' + message)
  },
  err (message) {
    console.log('error - ' + message)
  }
}

export default logs
