import Server from '../../service/axios'
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  timer: 1500,
  showCloseButton: true,
  timerProgressBar: true,
  showConfirmButton: false,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
}) 

const shop = {

  namespaced: true,

  state: {
    cart: {},
    wishlist: {},
    coupon: {},
    order: {},
    payment: {}
  },

  mutations: {
    syncUserCart (state, data) {
      state.cart = data.data
    },
    syncUserWishlist (state, data) {
      state.wishlist = data.data
    },
    applyDiscountCoupon (state, data) {
      state.coupon = data
    },
    removeDiscountCoupon (state) {
      state.coupon = {}
    },
    saveOrder (state, data) {
      state.order = data
    },
    savePayment (state, data) {
      state.payment = data
    },
    resetUserCart (state) {
      state.cart = {}
    },
    resetUserWishlist (state) {
      state.wishlist = {}
    },
    resetUserCoupon (state) {
      state.coupon = {}
    },
    resetUserOrder (state) {
      state.order = {}
    },
    resetUserPayment (state) {
      state.payment = {}
    }
  },

  actions: {
    // Initial load
    loadAllUserData ({ dispatch }) {
      dispatch('syncUserCartItems').then(() => {
        dispatch('syncUserWishlistItems')
      })
    },
    async syncUserCartItems ({ commit }) {
      await Server
        .get('shopy/v1/cart/sync')
        .then(response => {
          commit('syncUserCart', response.data)
        })
    },
    async syncUserWishlistItems ({ commit }) {
      await Server
        .get('shopy/v1/wishlist/sync')
        .then(response => {
          commit('syncUserWishlist', response.data)
        })
    },
    // Working
    async sendRequestToUpdateCart ({ dispatch }, data) {
      await Server
        .post('shopy/v1/cart/update', data)
        .then(() => {
          dispatch('shop/syncUserCartItems', null, { root: true }).then(() => {
            Toast.fire({
              icon: 'success',
              title: 'Cart Updated'
            })
          })
        })
    },
    async sendRequestToUpdateWishlist ({ dispatch }, data) {
      await Server
        .post('shopy/v1/wishlist/update', data)
        .then(() => {
          dispatch('shop/syncUserWishlistItems', null, { root: true }).then(() => {
            Toast.fire({
              icon: 'success',
              title: 'Wishlist Updated'
            })
          })
        })
    },
    async sendRequestToMoveToCart ({ dispatch }, data) {
      dispatch('shop/sendRequestToUpdateWishlist', data, { root: true }).then(() => {
        dispatch('shop/sendRequestToUpdateCart', data, { root: true })
      })
    },
    async verifyAndApplyCouponCode ({ commit }, data) {
      await Server
        .post('shopy/v1/checkout/coupon', data)
        .then((response) => {
          Toast.fire({
            icon: 'success',
            title: 'Discount Coupon Applied'
          })
          commit('applyDiscountCoupon', response.data)
        })
    },
    async removeCouponCode ({ commit }) {
      Toast.fire({
        icon: 'success',
        title: 'Discount Coupon Removed'
      })
      commit('removeDiscountCoupon')
    },
    async sendRequestToCreateOrder ({ commit, dispatch }, data) {
      await Server
        .post('shopy/v1/order/create', data)
        .then((response) => {
          localStorage.setItem('order_id', response.data.data.razorpay_id)
          localStorage.setItem('order_status', response.data.data.status)
          dispatch('endLoading', null, { root: true })
          commit('saveOrder', response.data.data)
        })
    },
    async sendRequestToCreatePayment ({ commit, dispatch }, data) {
      await Server
        .post('shopy/v1/payment/create', data)
        .then((response) => {
          dispatch('endLoading', null, { root: true })
          commit('savePayment', response.data.data)
        })
    },
    // Reset
    resetAllUserData ({ dispatch }) {
      dispatch('resetUserCart').then(() => {
        dispatch('resetUserWishlist').then(() => {
          dispatch('resetUserCoupon').then(() => {
            dispatch('resetUserOrder').then(() => {
              dispatch('resetUserPayment')
            })
          })
        })
      })
    },
    resetUserCart ({ commit }) {
      commit('resetUserCart')
    },
    resetUserWishlist ({ commit }) {
      commit('resetUserWishlist')
    },
    resetUserCoupon ({ commit }) {
      commit('resetUserCoupon')
    },
    resetUserOrder ({ commit }) {
      commit('resetUserOrder')
    },
    resetUserPayment ({ commit }) {
      commit('resetUserPayment')
    }
  },

  getters: {
    getCart (state) {
      return state.cart
    },
    getWishlist (state) {
      return state.wishlist
    },
    getCoupon (state) {
      return state.coupon
    },
    getOrder (state) {
      return state.order
    },
    getPayment (state) {
      return state.payment
    }
  }
}

export default shop
