import { createRouter, createWebHistory } from 'vue-router'
import PayPage from '../pages/PayPage.vue'
import WebRoutesModule from './modules/web'
import ShopRoutesModule from './modules/shop'
import AuthRoutesModule from './modules/auth'
import UserRoutesModule from './modules/user'

const routes = [
  {
    path: '/pay',
    name: 'Pay',
    component: PayPage
  },
  {
    path: '/verify',
    name: 'Verify',
    component: () => import('@/pages/auth/VerifyEmail.vue'),
    beforeEnter: (to, from) => {
      return !!localStorage.getItem('login')
    }
  },
  WebRoutesModule,
  AuthRoutesModule,
  ShopRoutesModule,
  UserRoutesModule,
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/pages/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0, behavior: 'smooth'}
  }
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // Scroll to page top on route change
  // window.scrollTo(0, 0)

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
