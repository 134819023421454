<template>

    <footer id="appFooter" class="bg-danger text-white">

        <div class="container-xl text-center text-lg-start ">

            <div class="row text-center text-md-start mt-4">

                <!-- Brand Details -->
                <div class="col-xl-3 text-center text-xl-start h-100 py-5">
                    <img :src="footer.logo" :alt="footer.brand" class="mb-3" style="height: 60px">
                    <p class="font-brand text-white ts-1 mb-0 fw-bold display-6 text-center text-xl-start" v-html="footer.brand"></p>
                </div>

                <!-- Userful Links -->
                <div class="col-lg-6 col-xl-3 border-1-top border-lg-none border-secondary py-5">
                    <p class="lead font-title ls-1 border-bottom w-fc px-5 mx-auto ms-xl-0">Links</p>
                    <div class="d-flex flex-column align-items-center align-items-xl-start">
                        <router-link v-for="(link, index) in footer.links" :key="index" :to="{ name: link.name}"
                            class="btn fs-5 py-1" style="color: inherit;">{{ link.title }}
                        </router-link>
                    </div>
                </div>

                <!-- Contact Links -->
                <div class="col-lg-6 col-xl-3 border-1-top border-lg-none border-secondary py-5">
                    <p class="lead font-title ls-1 border-bottom w-fc px-5 mx-auto ms-xl-0">Actions</p>
                    <div class="d-flex flex-column align-items-center align-items-xl-start">
                        <a v-for="(link, index) in footer.git" :key="index" :href="link.action" target="_blank"
                            class="btn fs-5 py-1 text-white">
                            <i class="bi pe-2 pe-lg-2 fs-5 text-white" :class="link.icon"></i>{{ link.title}}
                        </a>
                    </div>
                </div>

                <!-- Google Map -->
                <div class="col-xl-3 mx-auto my-auto overflow-hidden">
                    <iframe :src="footer.map" class="rounded" width="100%" height="212" style="border:0;"
                    allowfullscreen="true" name="TZP Magazine office, google location map"
                    loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>

            </div>

        </div>

    </footer>

</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    footer () {
      return this.$store.getters['repo/getFooter']
    }
  }
}
</script>
