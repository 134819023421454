<template>

  <button type="button" v-if="show === 'btn'" class="btn position-relative" data-bs-toggle="offcanvas"
      data-bs-target="#userWishlistModal" aria-controls="userWishlistModal">
      <i v-if="Object.keys(wishlist).length > 0" class="bi bi-heart-fill text-danger fs-4"></i>
      <i v-else class="bi bi-heart text-white fs-4"></i>
  </button>

  <div v-if="show === 'view'" class="offcanvas offcanvas-end" tabindex="-1" id="userWishlistModal"
      aria-labelledby="userWishlistModalLabel">
      <div class="offcanvas-header bg-dark text-white" style="height: 85px">
          <div class="d-flex flex-column">
              <p class="offcanvas-title mb-0 ls-1 font-title" id="userWishlistModalLabel">
                  My wishlist
              </p>
              <p v-if="wishlist.units > 0" class="mb-0 small ls-1">has {{ wishlist.units }} units</p>
              <p v-else class="mb-0 small ls-1">has no product</p>
          </div>
          <button type="button" class="btn text-white" data-bs-dismiss="offcanvas" aria-label="Close">
              <i class="bi bi-x-lg fs-5"></i>
          </button>
      </div>
      <div class="offcanvas-body p-0">
        <div v-if="Object.keys(wishlist).length > 0">
          <div class="card border-0 border-bottom mb-2" v-for="(product, index) in wishlist" :key="index">
              <div class="card-body d-flex p-2">
                  <a :href="brand.self_url + '/products/' + product.slug" class="td-none">
                    <img :src="product.thumb" :alt="product.name" class="w-75p of-cover rounded">
                  </a>
                  <div class="d-flex flex-column ms-2 flex-fill">
                    <a :href="brand.self_url + '/products/' + product.slug" class="td-none">
                      <p class="fs-5 fw-bold text-muted mb-0" v-text="product.name"></p>
                      <p class="small mb-0">
                          <span class="text-muted me-2">Best Price :</span>
                          <span class="text-dark price fs-6" v-text="product.price"></span>
                          <span class="ms-2" v-if="product.discount">(Discount</span>
                          <span class="text-success price ms-1" v-if="product.discount"
                              v-text="(product.mrp - product.price)"></span>
                          <span class="ms-1" v-if="product.discount">)</span>
                      </p>
                    </a>
                    <div class="btn-group mt-auto" role="group">
                        <button  type="button"
                          @click.once="moveItemToCart(product.slug)"
                          class="btn btn-sm btn-outline-secondary">
                            <i class="bi bi-bag me-2"></i>Move to cart
                        </button>
                        <button type="button"
                          @click.once="removeFromWishlist(product.slug)"
                          class="btn btn-sm btn-outline-secondary">
                            <i class="bi bi-trash3 me-2"></i>Remove
                        </button>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <div v-else class="p-4">
            Wishlist is empty
        </div>
      </div>
  </div>

</template>

<script>
export default {
  name: 'WishlistView',
  props: ['show'],
  computed: {
    wishlist () {
      return this.$store.getters['shop/getWishlist']
    },
    brand () {
      return this.$store.getters['repo/getBrand']
    }
  },
  methods: {
    moveItemToCart (product) {
      this.$store.dispatch('shop/sendRequestToMoveToCart', {
        action: 'add',
        product: product
      })
    },
    removeFromWishlist (product) {
      this.$store.dispatch('shop/sendRequestToUpdateWishlist', { product: product })
    }
  }
}

</script>
