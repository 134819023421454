
export default {
  path: '/',
  children: [
    {
      path: 'customer-support',
      name: 'CustomerSupport',
      component: () => import('@/pages/web/CustomerSupport.vue')
    },
    {
      path: 'shipping-and-delivery-policy',
      name: 'DeliveryPolicy',
      component: () => import('@/pages/web/ShippingAndDeliveryPolicy.vue')
    },
    {
      path: 'privacy-policy',
      name: 'PrivacyPolicy',
      component: () => import('@/pages/web/PrivacyPolicyPage.vue')
    },
    {
      path: 'terms-and-conditions',
      name: 'TermsAndConditions',
      component: () => import('@/pages/web/TermsAndConditionsPage.vue')
    },
    {
      path: 'cancellation-and-refund-policy',
      name: 'RefundPolicy',
      component: () => import('@/pages/web/CancellationAndRefundPolicy.vue')
    }
  ]
}
