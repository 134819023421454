const repository = {
  namespaced: true,
  state: {
    brand: {
      title: 'Aaahsaass',
      name: 'aaahsaass',
      tagline: 'Aaahsaass Sukoon Ka……',
      logo: require('../../assets/logo.png'),
      favicon: require('../../assets/favicon.jpg'),
      // parent_url: 'http://localhost:8080',
      // self_url: 'http://localhost:8080',
      parent_url: 'https://aaahsaass.com/',
      self_url: 'https://shop.aaahsaass.com',
      cover: {
        mobile: require('../../assets/shop/mobile.jpg'),
        desktop: require('../../assets/shop/desktop.jpg')
      }
    },
    footer: {
      brand: 'Aaahsaass<br>Foundation',
      logo: require('../../assets/logo-for-modal.jpg'),
      links: [
        { name: 'DeliveryPolicy', title: 'Shipping & Delivery' },
        { name: 'PrivacyPolicy', title: 'Privacy Policy' },
        { name: 'TermsAndConditions', title: 'Terms & Conditions' },
        // { name: 'RefundPolicy', title: 'Terms & Conditions' },
        // { name: 'CustomerSupport', title: 'Customer Support' },
      ],
      git: {
        1: {
          title: 'Send an email',
          icon: 'bi-envelope-fill text-warning me-2',
          action: 'mailto:enquiry@aaahsaass.com?Subject=Hello%20..'
        },
        2: {
          title: 'Call 9354629573',
          icon: 'bi-telephone-fill text-primary me-2',
          action: 'tel:+919354629573'
        },
        3: {
          title: 'Connect Whatsapp',
          icon: 'bi-whatsapp text-success me-2',
          action: 'https://wa.me/+919354629573?text=Namaste%20Gurusshri%20...'
        }
      },
      map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14000.45122197557!2d77.161393!3d28.686272000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x969557501ad28a24!2sSANJAY%20SHARMA%20AAAHSAASS%20(Astrologer)!5e0!3m2!1sen!2sin!4v1668061702951!5m2!1sen!2sin'
    },
    copyright: {
      brand: 'Aaahsaass Foundation',
      year: '© 2022 Copyright:',
      website: 'https://aaahsaass.come'
    }
  },
  mutations: {},
  actions: {},
  getters: {
    getBrand (state) {
      return state.brand
    },
    getBrandName (state) {
      return state.brand.name
    },
    getFooter (state) {
      return state.footer
    },
    getCopyright (state) {
      return state.copyright
    }
  }

}

export default repository
