import Api from '../../service/api'
import Logs from '../../service/logs'
import Server from '../../service/axios'
import Storage from '../../service/storage'
import Cache from '../../service/cache'

const data = {

  namespaced: true,

  state: {
    categories: null,
    products: null,
    category: null,
    product: null,
    loading: true,
    errors: ''
  },

  mutations: {
    updateErrors (state, error) {
      state.errors = error
      if (Logs.enabled) { Logs.err(error) }
    },
    updateProducts (state, modelData) {
      state.products = { ...modelData }
      if (Logs.enabled) { Logs.step('data mutated') }
    },
    updateCategories (state, modelData) {
      state.categories = { ...modelData }
      if (Logs.enabled) { Logs.step('data mutated') }
    },
    updateCategory (state, modelData) {
      state.category = { ...modelData }
      if (Logs.enabled) { Logs.step('data mutated') }
    },
    updateProduct (state, modelData) {
      state.product = { ...modelData }
      if (Logs.enabled) { Logs.step('data mutated') }
    },
    unloadModalData (state) {
      state.brand = {}
      state.category = {}
      state.product = {}
      if (Logs.enabled) { Logs.step('data unloaded') }
    },
    updateLoadingState (state, loading) {
      state.loading = loading
      if (Logs.enabled) { Logs.step('state updated') }
    }
  },

  actions: {
    loadingState ({ commit }, loading) {
      commit('updateLoadingState', loading)
    },
    loadData ({ dispatch }, model) {
      if (Logs.enabled) { Logs.step('data loading') }
      // Data can be retrived only after
      // a. It must be a collection
      // b. Had been stored previously
      if (Cache.enabled) {
        const isCollection = model.value === null
        const hasLocalData = Storage.hasData(model.key)
        if (isCollection && hasLocalData) {
          // Get the data from local storage
          model.value = Storage.getData(model.key)
          if (Logs.enabled) { Logs.step('data retrived') }
          // EXIT: Pass it to update vuex store
          dispatch('updateData', model)
        }
      }
      // Else simply fetch new data
      dispatch('fetchData', model)
    },
    async fetchData ({ dispatch }, model) {
      await Server(Api.getPath(model.key, model.value))
        .then(response => {
          if (Logs.enabled) { Logs.step('data fetched') }
          const isCollection = model.value === null
          model.value = response.data.data
          if (isCollection) {
            Storage.saveData(model)
          }
          dispatch('updateData', model)
        })
        .catch((error) => {
          if (Logs.enabled) { Logs.step('data not fetched') }
          dispatch('updateError', error)
        })
    },
    updateData ({ commit }, model) {
      if (Logs.enabled) { Logs.step('data committing') }
      commit(Cache.getMutator(model.key), model.value)
    },
    unloadData ({ commit }) {
      if (Logs.enabled) { Logs.step('data unloading') }
      commit('unloadModalData')
    },
    updateError ({ commit }, error) {
      commit('updateErrors', error)
    }
  },
  getters: {
    getCategories (state) {
      return state.categories
    },
    getProducts (state) {
      return state.products
    },
    getCategory (state) {
      return state.category
    },
    getProduct (state) {
      return state.product
    },
    getLoadingState (state) {
      return state.loading
    }
  }

}

export default data
