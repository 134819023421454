import Server from '../../service/axios'
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  timer: 3000,
  timerProgressBar: true,
  showConfirmButton: false,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})   

export default {
  namespaced: true,

  state: {
    login: false, // state that wheather the user is logged in or not
    user: {
      name: 'Guest',
      email: null,
      verified: false,
      access_token: null
    }
  },

  getters: {
    isLoggedIn: state => state.login,
    getUser: state => state.user,
    username: state => state.user.name,
    useremail: state => state.user.email,
    verified: state => state.user.verified,
    token: state => state.user.access_token
  },

  mutations: {
    setUser (state, user) {
      if (user.access_token !== null && user.access_token !== 'null') {
        // 1. to enable instant onsite changes
        state.login = true
        // 2. for display at auth pages
        state.user = user
        // 3. to be restored from after refresh
        localStorage.setItem('username', user.name)
        localStorage.setItem('useremail', user.email)
        localStorage.setItem('access_token', user.access_token)
        if (user.verified === 'true') {
          localStorage.setItem('verified', true)
        } else {
          localStorage.setItem('verified', false)
        }
        // computed
        localStorage.setItem('login', true)
      }
    },
    unsetUser (state) {
      state.login = false
      state.user = {
        name: 'Guest',
        email: null,
        verified: false,
        access_token: null
      }
      localStorage.clear()
    },
    markVerified (state) {
      state.user.verified = true
      localStorage.setItem('verified', true)
    }
  },

  actions: {
    // Run on every page refresh
    async loadAuthFromLocalStorage ({ commit, dispatch }) {
      if (localStorage.getItem('login') === 'true') {
        if (localStorage.getItem('verified') === 'false') {
          await dispatch('checkVerifiedEmailRequest')
        }
        await commit('setUser', {
          name: localStorage.getItem('username'),
          email: localStorage.getItem('useremail'),
          verified: localStorage.getItem('verified'),
          access_token: localStorage.getItem('access_token')
        })
      }
    },
    // User need to initiate them (un-authenticated request)
    async sendRegisterRequest ({ commit, dispatch }, data) {
      await Server
        .post('authy/v1/register', data)
        .then(response => {
          if (response.status === 201) {
            // Update axios header with latest token
            Server.defaults.headers.common.Authorization = `Bearer ${response.data.data.access_token}`
            dispatch('endLoading', null, { root: true })
            Toast.fire({
              icon: 'success',
              title: 'Welcome New User'
            })
            commit('setUser', response.data.data)
          }
        })
    },
    async sendLoginRequest ({ commit, dispatch }, data) {
      await Server
        .post('authy/v1/login', data)
        .then(response => {
          if (response.status === 200) {
            Server.defaults.headers.common.Authorization = `Bearer ${response.data.data.access_token}`
            dispatch('shop/loadAllUserData', null, { root: true }).then(() => {
              dispatch('endLoading', null, { root: true }).then(() => {
                Toast.fire({
                  icon: 'success',
                  title: 'Login successfully'
                })
                commit('setUser', response.data.data)
              })
            })
          }
        })
    },
    async requestForgotPasswordEmail ({ dispatch }, data) {
      await Server
        .post('authy/v1/forgot', data)
        .then(() => {
          dispatch('endLoading', null, { root: true })
        })
    },
    // User need to initiate them (authenticated request)
    async checkVerifiedEmailRequest ({ commit, dispatch }) {
      await Server
        .get('authy/v1/verified')
        .then(response => {
          if (response.data.data[0] === 'verified') {
            commit('markVerified')
          }
        })
    },
    async sendLogoutRequest ({ commit, dispatch }) {
      await Server
        .post('authy/v1/logout')
        .then(() => {
          dispatch('shop/resetAllUserData', null, { root: true }).then(() => {
            dispatch('endLoading', null, { root: true }).then(() => {
              Toast.fire({
                icon: 'success',
                title: 'Session Logged Out'
              })
              commit('unsetUser')
            })
          })
        })
    },
    clearAuthData ({ commit, dispatch }) {
      dispatch('endLoading', null, { root: true }).then(() => {
        commit('unsetUser')
      })
    },
    async requestEmailVerificationLink ({ dispatch }, data) {
      await Server
        .post('authy/v1/verify', data)
        .then(() => {
          dispatch('endLoading', null, { root: true })
        })
    },
    async updateUserPassword ({ dispatch }, data) {
      await Server
        .post('authy/v1/update-password', data)
        .then(() => {
          dispatch('endLoading', null, { root: true })
        })
    }
  }
}
