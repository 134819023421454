import Repo from '../store/modules/repo'

const cache = {

  enabled: true,

  categories: Repo.state.brand.name + '-categories',
  products: Repo.state.brand.name + '-products',
  category: Repo.state.brand.name + '-category',
  product: Repo.state.brand.name + '-product',
  addtocart: Repo.state.brand.name + '-addtocart',
  addtolist: Repo.state.brand.name + '-addtolist',

  getMutator (key) {
    switch (key) {
      case this.categories: return 'updateCategories'
      case this.products: return 'updateProducts'
      case this.category: return 'updateCategory'
      case this.product: return 'updateProduct'
    }
  },

  isObject (key) {
    switch (key) {
      case this.categories: return false
      case this.products: return false
      case this.category: return true
      case this.product: return true
    }
  },

  isCollection (key) {
    switch (key) {
      case this.categories: return true
      case this.products: return true
      case this.category: return false
      case this.product: return false
    }
  }

}

export default cache
