import Server from '../../service/axios'

export default {
  namespaced: true,

  state: {
    user: {},
    orders: {}
  },

  getters: {
    user: state => state.user,
    getOrders: state => state.orders
  },

  mutations: {
    setUser (state, user) {
      state.user = user
    },
    syncOrders (state, data) {
      state.orders = data
    }
  },

  actions: {
    async fetchUsersAppData ({ commit }) {
      await Server
        .get('authy/v1/appdata')
        .then(response => {
          if (response.status === 200) {
            commit('setUser', response.data.data)
          }
        })
    },
    async unsetAppData ({ commit }) {
      await commit('setUser', {})
    },
    async syncOrders ({ commit }) {
      await Server
        .get('shopy/v1/order/sync')
        .then(response => {
          commit('syncOrders', response.data.data)
        })
    },
    async requestOrderCancelation ({ commit }, order) {
      await Server
        .get('shopy/v1/order/cancel/' + order.razorpay_id)
        .then(response => {
          commit('syncOrders', response.data.data)
        })
    }
  }
}

// state: {
//   user: {},
//   pendingOrders: {},
//   completedOrders: {},
//   canceledOrders: {}
// },

// getters: {
//   user: state => state.user,
//   getPendingOrders: state => state.pendingOrders,
//   getCompletedOrders: state => state.completedOrders,
//   getCanceledOrders: state => state.canceledOrders
// },

// mutations: {
//   setUser (state, user) {
//     state.user = user
//   },
//   syncPendingOrders (state, data) {
//     state.pendingOrders = data
//   },
//   syncCompletedOrders (state, data) {
//     state.completedOrders = data
//   },
//   syncCanceledOrders (state, data) {
//     state.canceledOrders = data
//   }
// },

// actions: {
//   async fetchUsersAppData ({ commit }) {
//     await Server
//       .get('authy/v1/appdata')
//       .then(response => {
//         if (response.status === 200) {
//           commit('setUser', response.data.data)
//         }
//       })
//   },
//   async unsetAppData ({ commit }) {
//     await commit('setUser', {})
//   },
//   async fetchPendingOrders ({ commit }) {
//     await Server
//       .get('shopy/v1/order/sync/pending')
//       .then(response => {
//         commit('syncPendingOrders', response.data.data)
//       })
//   },
//   async fetchCompletedOrders ({ commit }) {
//     await Server
//       .get('shopy/v1/order/sync/completed')
//       .then(response => {
//         commit('syncCompletedOrders', response.data.data)
//       })
//   },
//   async fetchCanceledOrders ({ commit }) {
//     await Server
//       .get('shopy/v1/order/sync/canceled')
//       .then(response => {
//         commit('syncCanceledOrders', response.data.data)
//       })
//   }
// }
