<template>

  <button type="button" v-if="show === 'btn'" class="btn position-relative p-0 ms-2" data-bs-toggle="offcanvas"
      data-bs-target="#userCartModal" aria-controls="userCartModal">
      <i class="bi bi-bag-fill text-white fs-4"></i>
      <span v-if="cart.units" class="position-absolute top-50 start-100 translate-middle badge rounded-pill bg-danger">
          {{ cart.count }}
          <span class="visually-hidden">products in cart</span>
      </span>
  </button>

  <div v-if="show === 'view'" class="offcanvas offcanvas-end" tabindex="-1" id="userCartModal"
      aria-labelledby="userCartModalLabel">
      <div class="offcanvas-header bg-dark text-white" style="height: 85px">
          <div class="d-flex flex-column">
              <p class="offcanvas-title mb-0 ls-1 font-title" id="userCartModalLabel">
                  My Cart
              </p>
              <p v-if="cart.units > 0" class="mb-0 small ls-1">has {{ cart.units }} units</p>
              <p v-else class="mb-0 small ls-1">has no product</p>
          </div>
          <button id="closeCartView" type="button" class="btn text-white" data-bs-dismiss="offcanvas" aria-label="Close">
              <i class="bi bi-x-lg fs-5"></i>
          </button>
      </div>
      <div class="offcanvas-body p-0">
        <div v-if="cart.units">
          <div class="card border-0 border-bottom mb-2" v-for="(item, index) in cart.items" :key="index">
              <div class="card-body d-flex p-2">
                  <a :href="brand.self_url + '/products/' + item.product.slug" class="td-none">
                    <img :src="item.product.thumb" :alt="item.product.name" class="w-75p of-cover rounded">
                  </a>
                  <a class="d-flex flex-column ms-2 flex-fill td-none" :href="brand.self_url + '/products/' + item.product.slug">
                      <p class="fs-5 fw-bold text-muted mb-0" v-text="item.product.name"></p>
                      <p class="small mb-0">
                          <span class="text-muted me-2">Quantity :</span>
                          <span class="text-dark fs-6" v-text="item.quantity"></span>
                          <span class="text-muted ms-2">unit{{ item.quantity > 1 ? 's' : '' }}</span>
                      </p>
                      <p class="small mb-0">
                          <span class="text-muted me-2">Best Price :</span>
                          <span class="text-dark price fs-6" v-text="item.product.price"></span>
                          <span class="ms-2" v-if="item.product.discount">(Discount</span>
                          <span class="text-success price ms-1" v-if="item.product.discount"
                              v-text="(item.product.mrp - item.product.price) * item.quantity"></span>
                          <span class="ms-1" v-if="item.product.discount">)</span>
                      </p>
                  </a>
                  <div class="btn-group-vertical h-fc shadow mb-auto" role="group">
                      <button type="button" :disabled="clicked"
                        @click="updateCart('add', item.product.slug)"
                        class="btn btn-sm border-0 btn-outline-secondary">
                          <i class="bi bi-plus fs-5"></i>
                      </button>
                      <button type="button" :disabled="clicked"
                        @click="updateCart('less', item.product.slug)"
                        class="btn btn-sm border-0 btn-outline-secondary border-top">
                          <i v-if="item.quantity > 1" class="bi bi-dash fs-5"></i>
                          <i v-else class="bi bi-trash3 fs-6"></i>
                      </button>
                  </div>
              </div>
          </div>
        </div>
        <div v-else class="p-4">
            Cart is empty
        </div>
      </div>
      <div class="offcanvas-header bg-dark text-white" style="cursor: pointer" @click="checkoutNow">
          <div class="d-flex flex-column">
              <p class="offcanvas-title mb-0 ls-1 font-title">
                  Checkout Now
              </p>
              <p class="mb-0 small ls-1">
                  Total Amount
                  <span class="price ms-1">{{ cart.total }}</span>
              </p>
          </div>
          <button class="btn">
              <i class="bi bi-chevron-right text-white fs-4"></i>
          </button>
      </div>
  </div>

</template>

<script>
import router from '../../router/index'
import Swal from 'sweetalert2'

export default {
  name: 'CartView',
  props: ['show'],
  data () {
    return {
      clicked: false
    }
  },
  computed: {
    cart () {
      return this.$store.getters['shop/getCart']
    },
    brand () {
      return this.$store.getters['repo/getBrand']
    }
  },
  methods: {
    updateCart (action, product) {
      this.clicked = true
      this.$store.dispatch('shop/sendRequestToUpdateCart', { action: action, product: product }).then(() => {
        this.clicked = false
      })
    },
    checkoutNow () {
      if (this.cart.items) {
        document.getElementById('closeCartView').click()
        router.push({ name: 'Checkout' })
      } else {
        Swal.fire({
          position: 'bottom-end',
          icon: 'warning',
          title: 'Cart is empty',
          showConfirmButton: false,
          timer: 1500
        })
      }
    }
  }
}

</script>
