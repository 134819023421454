import Cache from './cache'

const api = {
  allCategories: {
    method: 'get',
    url: 'shopy/v1/categories'
  },
  allProducts: {
    method: 'get',
    url: 'shopy/v1/products'
  },
  getCategory (model) {
    return {
      method: 'get',
      url: 'shopy/v1/categories/' + model
    }
  },
  getProduct (model) {
    return {
      method: 'get',
      url: 'shopy/v1/products/' + model
    }
  },
  addToCart (model) {
    return {
      method: 'post',
      url: 'shopy/v1/sync/cart',
      data: model
    }
  },
  getPath (key, model) {
    switch (key) {
      case Cache.categories: return this.allCategories
      case Cache.products: return this.allProducts
      case Cache.category: return this.getCategory(model)
      case Cache.product: return this.getProduct(model)
      case Cache.addtocart: return this.addToCart(model)
    }
  }
}

export default api
