const project = {

  stage: 'production',

  inDev () {
    return this.stage === 'development'
  },
  inProd () {
    return this.stage === 'production'
  },

  getServerUrl () {
    switch (this.stage) {
      case 'development': return 'http://localhost:8000'
      case 'production': return 'https://server.aaahsaass.com'
    }
  }
}

export default project
