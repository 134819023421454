<template>
  <div class="d-flex flex-column min-vh-100" style="max-width: 100vw; overflow-x: hidden;">

    <AppNavbar/>
    <PageLoading/>

    <main role="main" style="margin-top: 36px; min-height: 75vh" class="flex-fill d-flex">
      <div class="flex-fill">
        <router-view v-slot="{Component}">
          <transition name="fade" mode="out-in">
            <component :is="Component" :key="$route.path"></component>
          </transition>
        </router-view>
      </div>
    </main>

    <AppFooter />
    <AppCopyright/>

  </div>
</template>

<script>
import './template/js/index.js'
import './template/sass/index.scss'
import AppNavbar from './components/AppNavbar.vue'
import AppFooter from './components/AppFooter.vue'
import AppCopyright from './components/AppCopyright.vue'
import PageLoading from './components/includes/PageLoading.vue'

export default {
  name: 'App',
  components: {
    AppNavbar,
    AppFooter,
    AppCopyright,
    PageLoading
  },
  mounted () {
    // This will sync the auth data between pre & post refresh of application
    if (localStorage.getItem('login')) {
      this.$store.dispatch('auth/loadAuthFromLocalStorage').then(() => {
        this.$store.dispatch('auth/checkVerifiedEmailRequest').then(() => {
          this.$store.dispatch('shop/loadAllUserData')
        })
      })
    }
  }
}
</script>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
 